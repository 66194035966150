import { httpService } from 'core-utilities';
import { eventStreamService } from 'core-roblox-utilities';
import {
  getEmailUrlConfig,
  getEmailVerificationUrlConfig,
  getMetadataV2UrlConfig
} from '../constants/urlConstants';
import events from '../constants/verificationUpsellEventStreamConstants';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';

export const sendVerificationUpsellEvent = (event, params) => {
  eventStreamService.sendEventWithTarget(event.type, event.context, {
    ...event.params,
    origin: params.origin
  });
};

export const sendEmailVerification = () => {
  const urlConfig = getEmailVerificationUrlConfig();
  return httpService.post(urlConfig).then(
    () => {
      return true;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const updateEmailAddress = formData => {
  const urlConfig = getEmailUrlConfig();
  return httpService.post(urlConfig, formData).then(
    () => {
      return true;
    },
    e => {
      return e.data.errors && e.data.errors[0].code;
    }
  );
};

export const getUserEmailStatusAndOpenModal = (origin, skipCallback) => {
  const urlConfig = getEmailUrlConfig();
  return httpService.get(urlConfig).then(
    ({ data }) => {
      if (!data?.emailAddress) {
        const event = new CustomEvent('OpenVerificationModal', {
          detail: {
            isEmailVerified: data.verified,
            email: data?.emailAddress,
            skipCallback,
            origin
          }
        });
        sendVerificationUpsellEvent(events.showModal, {
          origin
        });
        window.dispatchEvent(event);
      }
      return data;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const getMetadataV2 = () => {
  const urlConfig = getMetadataV2UrlConfig();
  return httpService.get(urlConfig);
};

export const handleUserEmailUpsellAtLogout = skipCallback => {
  return getMetadataV2()
    .then(
      ({ data }) => {
        // see if ab test is turned on
        if (data?.IsEmailUpsellAtLogoutEnabled) {
          const { Logout } = verificationUpsellConstants;
          return getUserEmailStatusAndOpenModal(Logout, skipCallback);
        }
        return false;
      },
      e => {
        console.log(e);
        return false;
      }
    )
    .catch(console.debug);
};

export const handleUserEmailUpsellAtBuyRobux = skipCallback => {
  const { BuyRobux } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal(BuyRobux, skipCallback);
};

export const handleUserEmailUpsellAtPremiumSubscription = skipCallback => {
  const { SubscriptionPurchase } = verificationUpsellConstants;
  return getUserEmailStatusAndOpenModal(SubscriptionPurchase, skipCallback);
};

export const getUserEmailAddress = () => {
  const urlConfig = getEmailUrlConfig();
  return httpService.get(urlConfig).then(
    ({ data }) => {
      return data?.emailAddress;
    },
    e => {
      console.log(e);
      return false;
    }
  );
};

export const validateEmailAddress = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
