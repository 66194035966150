import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useVerificationUpsellState from '../hooks/useVerificationUpsellState';
import VerificationUpsellModal from '../components/VerificationUpsellModal';
import {
  sendEmailVerification,
  updateEmailAddress,
  validateEmailAddress,
  sendVerificationUpsellEvent,
  getUserEmailAddress
} from '../services/userEmailServices';
import {
  SET_MODAL_STATES,
  SET_USER_EMAIL_STATES,
  SET_PAGENAME_STATE,
  SET_INPUT_STATE,
  SET_ERROR_STATE,
  SET_EMAIL_SENT_STATE,
  SET_RESEND_EMAIL_STATE,
  SET_EMAIL_UPDATING_STATE,
  SET_TRIGGER_ORIGIN,
  SET_INPUT_CLEAR
} from '../actions/actionTypes';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';
import events from '../constants/verificationUpsellEventStreamConstants';

const { MessageConfirmationEmailNotSent, MessageInvalidEmailAddress } = verificationUpsellConstants;

function VerificationUpsellModalContainer({ translate }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { verificationUpsellState, dispatch } = useVerificationUpsellState();

  window.addEventListener(
    'OpenVerificationModal',
    event => {
      setModalOpen(true);
      dispatch({
        type: SET_TRIGGER_ORIGIN,
        origin: event.detail.origin,
        skipCallback: event.detail.skipCallback
      });
      dispatch({
        type: SET_USER_EMAIL_STATES,
        isEmailVerified: event.detail.isEmailVerified,
        userEmail: event.detail.email
      });
      const pageName = verificationUpsellConstants.UpdateEmail;
      dispatch({ type: SET_PAGENAME_STATE, pageName });
      dispatch({ type: SET_MODAL_STATES });
    },
    false
  );

  function handlePrimaryAction() {
    switch (verificationUpsellState.pageName) {
      case verificationUpsellConstants.Verification:
        sendVerificationUpsellEvent(events.clickResendConfirmationEmail, {
          origin: verificationUpsellState.origin
        });
        sendEmailVerification().then(rst => {
          if (!rst) {
            dispatch({ type: SET_ERROR_STATE, errorMsg: MessageConfirmationEmailNotSent });
            sendVerificationUpsellEvent(events.showError, {
              origin: verificationUpsellState.origin
            });
          } else {
            dispatch({ type: SET_EMAIL_SENT_STATE });
            setTimeout(() => {
              dispatch({ type: SET_RESEND_EMAIL_STATE });
            }, 15000);
          }
        });
        break;
      case verificationUpsellConstants.UpdateEmail:
        sendVerificationUpsellEvent(events.clickContinue, {
          origin: verificationUpsellState.origin
        });
        if (validateEmailAddress(verificationUpsellState.userEmailInput)) {
          sendVerificationUpsellEvent(events.clickSendConfirmationEmail, {
            origin: verificationUpsellState.origin
          });
          dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: true });
          updateEmailAddress({
            emailAddress: verificationUpsellState.userEmailInput
          }).then(rst => {
            if (rst === verificationUpsellConstants.InvalidEmailCode) {
              sendVerificationUpsellEvent(events.showError, {
                origin: verificationUpsellState.origin
              });
              dispatch({ type: SET_ERROR_STATE, errorMsg: MessageInvalidEmailAddress });
              dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: false });
            } else {
              // after updating email address, send a verification email.
              getUserEmailAddress().then(userEmail => {
                if (!userEmail) {
                  sendVerificationUpsellEvent(events.showError, {
                    origin: verificationUpsellState.origin
                  });
                  dispatch({ type: SET_ERROR_STATE, errorMsg: MessageConfirmationEmailNotSent });
                } else {
                  dispatch({
                    type: SET_USER_EMAIL_STATES,
                    isEmailVerified: false,
                    userEmail
                  });
                  dispatch({ type: SET_INPUT_CLEAR });
                  sendEmailVerification().then(verificationSuccess => {
                    if (!verificationSuccess) {
                      sendVerificationUpsellEvent(events.showError, {
                        origin: verificationUpsellState.origin
                      });
                      dispatch({
                        type: SET_ERROR_STATE,
                        errorMsg: MessageConfirmationEmailNotSent
                      });
                    } else {
                      dispatch({
                        type: SET_PAGENAME_STATE,
                        pageName: verificationUpsellConstants.Verification
                      });
                      dispatch({ type: SET_MODAL_STATES });
                    }
                    dispatch({ type: SET_EMAIL_UPDATING_STATE, isEmailUpdating: false });
                  });
                }
              });
            }
          });
          dispatch({ type: SET_MODAL_STATES });
        } else {
          sendVerificationUpsellEvent(events.showError, {
            origin: verificationUpsellState.origin
          });
          dispatch({ type: SET_ERROR_STATE, errorMsg: MessageInvalidEmailAddress });
        }
        break;
      default:
    }
  }

  function handleSecondaryAction() {
    switch (verificationUpsellState.pageName) {
      case verificationUpsellConstants.Verification:
        sendVerificationUpsellEvent(events.clickChangeEmail, {
          origin: verificationUpsellState.origin
        });
        dispatch({ type: SET_PAGENAME_STATE, pageName: verificationUpsellConstants.UpdateEmail });
        dispatch({ type: SET_MODAL_STATES });
        break;
      case verificationUpsellConstants.UpdateEmail:
        if (verificationUpsellState.skipCallback) {
          sendVerificationUpsellEvent(
            verificationUpsellState.origin === verificationUpsellConstants.Logout
              ? events.skipLogoutAnyway
              : events.skipPrepurchaseVerification,
            {
              origin: verificationUpsellState.origin
            }
          );
          verificationUpsellState.skipCallback();
        }
        dispatch({ type: SET_INPUT_CLEAR });
        setModalOpen(false);
        break;
      default:
    }
  }

  function handleInputFocus() {
    sendVerificationUpsellEvent(events.touchEmail, {
      origin: verificationUpsellState.origin
    });
  }

  function handleInputChange(value) {
    dispatch({
      type: SET_INPUT_STATE,
      pageName: verificationUpsellConstants.UpdateEmail,
      value
    });
  }
  function handleKeyDown(value) {
    if (value === verificationUpsellConstants.Enter) {
      return handlePrimaryAction();
    }
    return false;
  }

  function handleBackAction() {
    dispatch({ type: SET_PAGENAME_STATE, pageName: verificationUpsellConstants.UpdateEmail });
    dispatch({ type: SET_MODAL_STATES });
  }

  function handleModalDismiss() {
    sendVerificationUpsellEvent(events.dismissModal, {
      origin: verificationUpsellState.origin
    });
    dispatch({ type: SET_INPUT_CLEAR });
    setModalOpen(false);
  }
  return (
    <VerificationUpsellModal
      show={isModalOpen}
      onHide={() => handleModalDismiss()}
      onPrimaryAction={() => handlePrimaryAction()}
      onSecondaryAction={() => handleSecondaryAction()}
      onInputFocus={() => handleInputFocus()}
      onInputChange={value => handleInputChange(value)}
      onKeyDown={value => handleKeyDown(value)}
      onBackAction={() => handleBackAction()}
      translate={translate}
    />
  );
}

VerificationUpsellModalContainer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default VerificationUpsellModalContainer;
