import { EnvironmentUrls } from 'Roblox';

const { accountSettingsApi, authApi } = EnvironmentUrls;

const getEmailUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email`
});

const getEmailVerificationUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email/verify`
});

const getMetadataV2UrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${authApi}/v2/metadata`
});

export { getEmailUrlConfig, getEmailVerificationUrlConfig, getMetadataV2UrlConfig };
