import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { CurrentUser } from 'Roblox';
import {
  SET_USER_EMAIL_STATES,
  SET_PAGENAME_STATE,
  SET_MODAL_STATES,
  SET_INPUT_STATE,
  SET_ERROR_STATE,
  SET_RESEND_EMAIL_STATE,
  SET_EMAIL_SENT_STATE,
  SET_EMAIL_UPDATING_STATE,
  SET_TRIGGER_ORIGIN,
  SET_INPUT_CLEAR
} from '../actions/actionTypes';
import verificationUpsellConstants from '../constants/verificationUpsellConstants';

const {
  ActionSendConfirmationEmail,
  ActionResendConfirmationEmail,
  ActionChangeEmail,
  ActionLogoutSkip,
  ActionGenericSkip,
  DescriptionVerifyEmailBody,
  DescriptionAddEmailTextOver13,
  DescriptionAddEmailTextUnder13,
  DescriptionLogoutTextOver13,
  DescriptionLogoutTextUnder13,
  HeadingVerifyEmail,
  HeadingAddEmail,
  HeadingVerifyOnLogout,
  LabelEmailInputPlaceholderOver13,
  LabelEmailInputPlaceholderUnder13
} = verificationUpsellConstants;

const initialState = {
  pageName: '',
  isModalOpen: false,
  isEmailVerified: false,
  isEmailSent: false,
  isEmailUpdating: false,
  origin: '',
  userEmail: '',
  userEmailInput: '',
  userEmailInputPlaceholder: LabelEmailInputPlaceholderOver13,
  errorMsg: '',
  titleText: HeadingVerifyEmail,
  bodyText: DescriptionVerifyEmailBody,
  primaryButtonText: ActionResendConfirmationEmail,
  secondaryButtonText: ActionChangeEmail,
  skipCallback: null
};

const VerificationUpsellModalStoreContext = createContext(initialState);

const verificationUpsellReducer = (oldState, action) => {
  switch (action.type) {
    case SET_INPUT_CLEAR:
      return {
        ...oldState,
        userEmailInput: ''
      };
    case SET_TRIGGER_ORIGIN:
      return {
        ...oldState,
        origin: action.origin,
        skipCallback: action.skipCallback
      };
    case SET_USER_EMAIL_STATES:
      return {
        ...oldState,
        isEmailVerified: action.isEmailVerified,
        userEmail: action.userEmail
      };
    case SET_PAGENAME_STATE:
      return {
        ...oldState,
        pageName: action.pageName
      };
    case SET_ERROR_STATE:
      return {
        ...oldState,
        errorMsg: action.errorMsg
      };
    case SET_EMAIL_UPDATING_STATE:
      return {
        ...oldState,
        isEmailUpdating: action.isEmailUpdating
      };
    case SET_EMAIL_SENT_STATE:
      return {
        ...oldState,
        isEmailSent: true
      };
    case SET_RESEND_EMAIL_STATE:
      return {
        ...oldState,
        isEmailSent: false
      };
    case SET_INPUT_STATE:
      if (oldState.pageName === verificationUpsellConstants.UpdateEmail) {
        return {
          ...oldState,
          userEmailInput: action.value,
          errorMsg: ''
        };
      }

      return oldState;

    case SET_MODAL_STATES:
      // Set modal states based on pageName
      if (oldState.pageName === verificationUpsellConstants.UpdateEmail) {
        // Note that different texts are shown for users under 13.
        const isUserOver13 = CurrentUser && CurrentUser.is13orOver;
        const logoutFlow = oldState.origin === verificationUpsellConstants.Logout;
        const over13String = logoutFlow
          ? DescriptionLogoutTextOver13
          : DescriptionAddEmailTextOver13;
        const under13String = logoutFlow
          ? DescriptionLogoutTextUnder13
          : DescriptionAddEmailTextUnder13;
        const headingString = logoutFlow ? HeadingVerifyOnLogout : HeadingAddEmail;
        const secondaryText = logoutFlow ? ActionLogoutSkip : ActionGenericSkip;
        return {
          ...oldState,
          pageName: verificationUpsellConstants.UpdateEmail,
          titleText: headingString,
          bodyText: isUserOver13 ? over13String : under13String,
          primaryButtonText: ActionSendConfirmationEmail,
          secondaryButtonText: secondaryText,
          errorMsg: '',
          userEmailInputPlaceholder: isUserOver13
            ? LabelEmailInputPlaceholderOver13
            : LabelEmailInputPlaceholderUnder13
        };
      }
      // oldState.pageName is verificationUpsellConstants.Verification)
      return {
        ...oldState,
        pageName: verificationUpsellConstants.Verification,
        titleText: HeadingVerifyEmail,
        bodyText: DescriptionVerifyEmailBody,
        primaryButtonText: ActionResendConfirmationEmail,
        secondaryButtonText: ActionChangeEmail,
        errorMsg: ''
      };
    default:
      return oldState;
  }
};

const VerificationUpsellModalStateProvider = ({ children }) => {
  const [verificationUpsellState, dispatch] = useReducer(verificationUpsellReducer, initialState);

  return (
    <VerificationUpsellModalStoreContext.Provider value={{ verificationUpsellState, dispatch }}>
      {children}
    </VerificationUpsellModalStoreContext.Provider>
  );
};

VerificationUpsellModalStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { VerificationUpsellModalStoreContext, VerificationUpsellModalStateProvider };
