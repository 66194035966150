import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from 'react-utilities';
import { translation } from './app.config';
import VerificationUpsellModalContainer from './container/VerificationUpsellModalContainer';
import { VerificationUpsellModalStateProvider } from './stores/VerificationUpsellModalStoreContext';

function App({ translate }) {
  return (
    <VerificationUpsellModalStateProvider>
      <VerificationUpsellModalContainer translate={translate} />
    </VerificationUpsellModalStateProvider>
  );
}

App.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslations(App, translation);
