const verificationUpsellConstants = {
  // page names
  Verification: 'Verification',
  UpdateEmail: 'UpdateEmail',
  UpdatePassword: 'UpdatePassword',
  // orgins
  Logout: 'logout',
  BuyRobux: 'buyRobuxPage',
  SubscriptionPurchase: 'premiumSubscriptionPage',
  // keys
  Enter: 'Enter',
  // error codes
  InvalidEmailCode: 9,
  InvalidPasswordCode: 8,
  // translation string keys
  ActionChangeEmail: 'Action.ChangeEmail',
  ActionResendConfirmationEmail: 'Action.ResendConfirmationEmail',
  ActionSendConfirmationEmail: 'Action.SendConfirmationEmail',
  ActionSent: 'Action.Sent',
  ActionLogoutSkip: 'Action.Logout',
  ActionGenericSkip: 'Action.GenericSkip',
  DescriptionAddEmailTextOver13: 'Description.AddEmailTextOver13',
  DescriptionAddEmailTextUnder13: 'Description.AddEmailTextUnder13',
  DescriptionLogoutTextOver13: 'Description.LogoutAddEmailTextOver13',
  DescriptionLogoutTextUnder13: 'Description.LogoutAddEmailTextUnder13',
  DescriptionEnterPassword: 'Description.EnterPassword',
  DescriptionVerifyEmailBody: 'Description.VerifyEmailBody',
  HeadingAddEmail: 'Heading.AddEmail',
  HeadingVerifyEmail: 'Heading.VerifyEmail',
  HeadingVerifyOnLogout: 'Heading.VerifyOnLogout',
  LabelEmailInputPlaceholderOver13: 'Label.EmailInputPlaceholderOver13',
  LabelEmailInputPlaceholderUnder13: 'Label.EmailInputPlaceholderUnder13',
  LabelPasswordInputPlaceholder: 'Label.PasswordInputPlaceholder',
  MessageConfirmationEmailNotSent: 'Message.ConfirmationEmailNotSent',
  MessageInvalidEmailAddress: 'Message.InvalidEmailAddress',
  MessageWrongPassword: 'Message.WrongPassword'
};

export { verificationUpsellConstants as default };
